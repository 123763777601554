@import url('https://fonts.googleapis.com/css?family=Satisfy');

html, body {
  height: 100vh;
  /* overflow: hidden; */
  background-image: url('https://picsum.photos/id/257/1500/1000');
}

.full-screen {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.card-style {
  text-align: center;
  font-family: 'Satisfy';
  color: rgb(0, 144, 196);
  margin: auto;
  width: 90%;
  padding: 20px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
  border-radius: 10px;
}

.row {
  display: flex;
  justify-content: space-around;
}

.column {
  display: grid;
}

a {
  text-decoration: none;
}

#tsparticles {
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  z-index: -1;
}